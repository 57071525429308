import React, { useState, useRef, useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Layout, Table, Row, Col, Select, Button, Empty } from 'antd';
import {
  HeaderLanding,
  SubHeaderLanding,
  Footer,
} from 'components/layout/components';
import { TableCell } from 'components';
import Apptrackservice from '../landingPageSmart/apptrack.service.js';
import './installersPage.css';
import { treeData } from './treeData.js';
import ReCAPTCHA from 'react-google-recaptcha';

const InstallersPage = () => {

  const [region, setRegion] = useState();
  const [municipality, setMunicipality] = useState();
  const [response, setResponse] = useState([]);
  const [captchaPass, setCaptchaPass] = useState(false);

  const recaptcha = useRef(null);

  const sortedData = treeData.sort((a, b) =>
    a.title.toUpperCase().localeCompare(b.title.toUpperCase())
  );
  const regionOptions = sortedData.map((r, index) => (
    <Select.Option value={r.id} key={r.id}>
      {r.title}
    </Select.Option>
  ));
  const [municipalityOptions, setMunicipalityOptions] = useState([]);

  const [searchPressed, setSearchPressed] = useState(false);
  const [loading, setLoading] = useState(false);

  const locale = {
    emptyText: !searchPressed ? (
      ' '
    ) : loading ? (
      <span style={{color:"#000000"}}>Παρακαλώ περιμένετε...</span>
    ) : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Δεν υπάρχουν αποτελέσματα"
      />
    ),
  };

  const onChange = event => {
    setSearchPressed(true);
    //console.log('selected region and municipality', region, municipality);
    if (captchaPass) {
      setLoading(true);
      if (region && municipality) {
        Apptrackservice.getSmrData(region, municipality)
        .then(data => {
            setResponse(data);
            setCaptchaPass(true);
          })
          .catch(() => console.log("Search Call was Unsuccessful."))
          .finally(() => setTimeout(() => setLoading(false), 3000)); //3 seconds after the call end the loading
      }
    } else {
      //it won't get here as search button is disabled when captchaPass false
      setSearchPressed(false);
      // setLoading(false);
      alert('Please verify the reCAPTCHA!');
    }
  };

  const onCaptchaChange = event => {
    //console.log('event has : ', event);
    const captchaValue = recaptcha.current.getValue(event);
    if (captchaValue) setCaptchaPass(true);
    else setCaptchaPass(false);
  }

  useEffect(() => {
    setSearchPressed(false);
    setResponse([]);
    setMunicipality();
    if (!region) {
      setMunicipalityOptions([]);
    } else {
      let options = treeData.filter(r => r.id === region)[0]?.children;
      let sortedOptions =
        options &&
        options.sort((a, b) =>
          a.title.toUpperCase().localeCompare(b.title.toUpperCase())
        );
      if (sortedOptions && sortedOptions.length) {
        setMunicipalityOptions(
          sortedOptions.map((m, index) => (
            <Select.Option value={m.id} key={m.key}>
              {m.title}
            </Select.Option>
          ))
        );
      } else setMunicipalityOptions([]);
    }
  }, [region]);

  useEffect(() => {
    setSearchPressed(false);
    setResponse([]);
  }, [municipality]);

  const ApplicationsColumns = [
    {
      title: 'Επωνυμία Επιχείρησης', //"Κωδικός",
      dataIndex: 'companyName',
      key: 'companyName',
      render: text => <TableCell text={text} />,
      width: 20,
    },
    {
      title: 'Επώνυμο', //"Κωδικός",
      dataIndex: 'legalSurname',
      key: 'legalSurname',
      render: text => <TableCell text={text} />,
      width: 20,
    },
    {
      title: 'Όνομα', //"Κωδικός",
      dataIndex: 'legalName',
      key: 'legalName',
      render: text => <TableCell text={text} />,
      width: 20,
    },
    // {
    //   title: 'Σχετικά με τον εγκαταστάτη', //"Κωδικός",
    //   dataIndex: 'marketing',
    //   key: 'marketing',
    //   render: text => <TableCell text={text} />,
    //   width: 500,
    // },
    {
      title: 'Τηλέφωνο Επικοινωνίας', //"Κωδικός",
      dataIndex: 'legalPhone',
      key: 'legalPhone',
      render: text => <TableCell text={text} />,
      width: 50,
    },
    {
      title: 'E-mail Επικοινωνίας', //"Κωδικός",
      dataIndex: 'legalEmail',
      key: 'legalEmail',
      render: text => <TableCell text={text} />,
      width: 50,
    },
  ];
  return (
    <>
      <Layout>
        <HeaderLanding className="header-landing" />
        {/* <SubHeaderLanding className="header-landing" /> */}
        <Content id="installers-page-content" className="main-content">
          <Row className="ant-row installers-banner" gutter={8}>
            <p id='installers-banner'>
            Διευκρινίζεται ότι η πραγματοποίηση των εργασιών από τον επιλεγμένο εγκαταστάτη στο πλαίσιο του Προγράμματος Smart Readiness <b>δεν ενέχει οποιαδήποτε υποχρέωση</b> από πλευράς του Διαχειριστή του κτηρίου ή άλλου Eνοίκου του, να συνάψει σύμβαση παροχής υπηρεσίας με συγκεκριμένο τηλεπικοινωνιακό πάροχο. Οποιαδήποτε προσπάθεια από τον επιλεγμένο εγκαταστάτη να καταστρατηγήσει τον κανόνα αυτόν, θα πρέπει να καταγγέλλεται άμεσα στην ΚτΠ ΜΑΕ, μέσω email στο <a href="mailto:smartreadiness@ktpae.gr">smartreadiness@ktpae.gr</a>, ή καλώντας στο Κέντρο Εξυπηρέτησης της Δράσης από 9:00 έως 17:00, στο 210-2153936, παρέχοντας όλα τα απαραίτητα στοιχεία προκειμένου η ΚτΠ ΜΑΕ να προχωρήσει στις δικές της ενέργειες.
            </p>          
          </Row>
          <Row className="ant-row" gutter={8}>
            <Col span={8}>
              <Select
                value={region}
                allowClear={true}
                placeholder="Περιφερειακή Ενότητα"
                onChange={e => setRegion(e)}
              >
                {regionOptions}
              </Select>
            </Col>
            <Col span={8}>
              <Select
                value={municipality}
                placeholder="Δήμος"
                disabled={!region}
                onChange={e => setMunicipality(e)}
              >
                {municipalityOptions}
              </Select>
            </Col>
            <Col span={4}>
              <Button
                onClick={onChange}
                className={`search-button ${
                  !region || !municipality || !captchaPass ? 'disabled' : ''
                }`}
                disabled={!region || !municipality || !captchaPass}
              >
                Αναζήτηση
              </Button>
            </Col>
            <Col span={4}></Col>
          </Row>
          <p id='captcha-instructions'>Επιλέξτε Περ. Ενότητα, Δήμο, επιβεβαιώστε το “I’m not a robot“ και έπειτα  πατήστε Αναζήτηση για να εμφανιστούν τα αποτελέσματα. </p>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={recaptcha}
            onChange={onCaptchaChange}
            className="captchaContainer"
          />
          <p style={{background:"#FFF4CE", color:"#955F2E", marginTop:"1rem"}}>
            <b>Η αίτηση υποβάλλεται από τον εγκαταστάτη εκ μέρους του δικαιούχου.</b>
            &nbsp;Συνεπώς, επικοινωνήστε με τον εγκαταστάτη της αρεσκείας σας μέσω e-mail ή τηλεφωνικά ώστε να προβεί στις απαραίτητες ενέργειες. 
          </p>
          {/* <Row className="ant-row">
            <Col span={20}></Col>
            <Col span={4}></Col>
          </Row> */}
          <div className="header-blue">
            <h1 className="header-blue">Πίνακας Εγκαταστατών</h1>
          </div>
          <Table
            rowClassName={(record, index) =>
              record.hasOwnProperty('entityIsProject')
                ? 'table-row-light-grey'
                : 'table-row-dark'
            }
            className="results-table"
            columns={ApplicationsColumns}
            dataSource={response}
            rowKey="id"
            locale={locale}
            pagination={{ showSizeChanger: false, showTitle: false }}
          ></Table>
        </Content>
        <Content className="landing-page-footer-smart">
          <Footer />
        </Content>
      </Layout>
    </>
  );
};

export default InstallersPage;
