import React, { useState, useRef } from 'react';
import { loginUrl, loginUrl2 } from 'utils';
import {
  Layout,
  Collapse,
  Table,
  Button,
  Select,
  Row,
  Col,
  Slider,
} from 'antd';
import {
  HeaderLanding,
  SubHeaderLanding,
  Footer,
} from 'components/layout/components';
import { TableCell } from 'components';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Wifi } from 'assets/icons/wifi.svg';
import SmartReadinessPdf from '../../utils/documents/Smart_Readiness_Οδηγός_Δράσης_9_7_2024.pdf';
import SmartReadinessPdf2 from '../../utils/documents/Πρόσκληση Smart Readiness επικαιροποιημένη.pdf';
import SmartReadinessKYA from '../../utils/documents/ΚΥΑ-smart-reandiness_16022023.pdf';
import SmartReadinessOdigos from '../../utils/documents/Smart_Readiness_Οδηγός_Δράσης_9_7_2024.pdf';
import Apptrackservice from './apptrack.service.js';
import Iframe from 'react-iframe';
import { pick, mapValues } from 'lodash';
import Guide from '../../utils/documents/Οδηγός_Επικοινωνίας_Ελλάδα_2_0_Δεκ_2021_FINAL.pdf';
import Guide1st from '../../utils/documents/1η_Απόφαση_Ένταξης_Μητρώου_Εγκαταστατών.pdf';
import Guide2nd from '../../utils/documents/2η_Απόφαση_Ένταξης_Μητρώου_Εγκαταστατών.pdf';
import Guide3rd from '../../utils/documents/3η_Απόφαση_Ένταξης_Μητρώου_Εγκαταστατών.pdf';
import Guide4th from '../../utils/documents/4η_Απόφαση_Ένταξης_Μητρώου_Εγκαταστατών.pdf';
import Guide5th from '../../utils/documents/5η_Απόφαση_Ένταξης_Μητρώου_Εγκαταστατών.pdf';
import Guide6th from '../../utils/documents/6η_Απόφαση_Ένταξης_Μητρώου_Εγκαταστατών.pdf';
import Guide7th from '../../utils/documents/7η_Απόφαση_Ένταξης_Μητρώου_Εγκαταστατών.pdf';
import Guide8th from '../../utils/documents/8η_Απόφαση_Ένταξης_Μητρώου_Εγκαταστατών.pdf';
import Guide9th from '../../utils/documents/9η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide10th from '../../utils/documents/10η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide11th from '../../utils/documents/11η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide12th from '../../utils/documents/12η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide13th from '../../utils/documents/13η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide14th from '../../utils/documents/14η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide15th from '../../utils/documents/15η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide16th from '../../utils/documents/16η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide17th from '../../utils/documents/17η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide18th from '../../utils/documents/18η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide19th from '../../utils/documents/19η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide20th from '../../utils/documents/20η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide21th from '../../utils/documents/21η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide22th from '../../utils/documents/22η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide23th from '../../utils/documents/23η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide24th from '../../utils/documents/24η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide25th from '../../utils/documents/25η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide26th from '../../utils/documents/26η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import Guide27th from '../../utils/documents/27η Απόφαση Ένταξης Μητρώου Εγκαταστατών.pdf';
import SmartReadinessKYARe from '../../utils/documents/Τροποποίηση ΚΥΑ_SR.pdf';
import SmartReadinessKYARe2 from '../../utils/documents/ΚΥΑ Εσωτερικών Εγκαταστάσεων.pdf';
import SmartReadinessKYARe3 from '../../utils/documents/2η Τροποποίηση ΚΥΑ_SR.pdf';
import AnakoinwseisPpt1 from '../../utils/documents/SR_Παρουσίαση της δράσης, πρόσφατες αλλαγές και βελτιώσεις.pptx'
import AnakoinwseisPpt2 from '../../utils/documents/SR Τεχνικές Προδιαγραφές Εσωτ. Δικτύου Ηλεκτρ. Επικοινωνιών.pptx'
import AnakoinwseisPpt3 from '../../utils/documents/Δικαιολογητικά Τεκμηρίωσης Κουπονιού.pptx'
import ReCAPTCHA from 'react-google-recaptcha';
import { Excel } from 'antd-table-saveas-excel';
import smartSlider from '../../assets/icons/smartSliderNew.png';
import epileximotita from '../../assets/icons/epileximotitaNew.png';
import egkatastatis from '../../assets/icons/egkatastatisNew.png';
import aitisi from '../../assets/icons/aitisi_purple.png';
import egkatastatis2 from '../../assets/icons/egkatastatis-2New.png';
import bot_img from 'assets/icons/Final_Chatobot_RGB.SVG';

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

const LandingPageSmart = () => {
  const redirect = useRef(null);
  const [response, setResponse] = useState([]);
  const [elegibility, setElegibility] = useState(false);

  const ApplicationsColumns = [
    {
      title: 'Επωνυμία Επιχείρησης', //"Κωδικός",
      dataIndex: 'companyName',
      key: 'companyName',
      render: text => <TableCell text={text} />,
      width: 20,
    },
    {
      title: 'Επώνυμο', //"Κωδικός",
      dataIndex: 'legalSurname',
      key: 'legalSurname',
      render: text => <TableCell text={text} />,
      width: 20,
    },
    {
      title: 'Όνομα', //"Κωδικός",
      dataIndex: 'legalName',
      key: 'legalName',
      render: text => <TableCell text={text} />,
      width: 20,
    },
    {
      title: 'Σχετικά με τον εγκαταστάτη', //"Κωδικός",
      dataIndex: 'marketing',
      key: 'marketing',
      render: text => <TableCell text={text} />,
      width: 500,
    },
    {
      title: 'Τηλέφωνο Επικοινωνίας', //"Κωδικός",
      dataIndex: 'legalPhone',
      key: 'legalPhone',
      render: text => <TableCell text={text} />,
      width: 50,
    },
    {
      title: 'E-mail Επικοινωνίας', //"Κωδικός",
      dataIndex: 'legalEmail',
      key: 'legalEmail',
      render: text => <TableCell text={text} />,
      width: 50,
    },
  ];

  const scrollToSection = elementRef => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const markerRef = useRef(null);

  const columns = [
    {
      title: 'Επιλέξιμες εργασίες',
      dataIndex: 'column1',
      key: 'column1',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Μονάδα μέτρησης',
      dataIndex: 'column2',
      key: 'column2',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Ποσό ενίσχυσης',
      dataIndex: 'column3',
      key: 'column3',
      render: text => <span>{text}</span>,
    },
  ];

  const handleExportToExcel = () => {
    const excel = new Excel();

    const updated = response.map(i =>
      mapValues(i, (value, key) => {
        return value;
      })
    );

    excel
      .addSheet('sheet 1')
      .addColumns(
        ApplicationsColumns.map(i =>
          pick(i, 'title', 'dataIndex', 'key')
        ).filter(k => k.key !== 'action')
      )
      .addDataSource(updated)
      .saveAs('Excel.xlsx');
  };

  const data = [
    {
      key: '1',
      column1: 'A',
      column2: 'Κτήριο (τοποθέτηση ΚΚ στο κτήριο)',
      column3: '200 €',
    },
    {
      key: '2',
      column1: 'A',
      column2: 'Όροφος (εφόσον τοποθετηθεί FB στον όροφο)',
      column3: '140 €',
    },
    {
      key: '3',
      column1: 'Β',
      column2: 'Κτήριο',
      column3: '60 €',
    },
    {
      key: '4',
      column1: 'Γ',
      column2: 'Κτήριο',
      column3: '60 €',
    },
    {
      key: '5',
      column1: 'Δ',
      column2: 'Κτήριο',
      column3: '60 €',
    },
    {
      key: '6',
      column1: 'Ε',
      column2: 'Κτήριο',
      column3: '60 €',
    },
  ];

  const documents = [
    {
      category: 'Κοινή Υπουργική Απόφαση',
      documents: [
        // {
        // 	label: '2η Τροποποίηση της Κοινής Υπουργικής Απόφασης',
        //   date: '16/12/2022',
        // 	link: SmartReadinessPdf
        // },
        {
          label: 'Κοινή Υπουργική Απόφαση',
          date: '23/02/2023',
          link: SmartReadinessKYA,
        },
        {
          label: 'Τροποποίηση ΚΥΑ_SR',
          date: '20/09/2023',
          link: SmartReadinessKYARe,
        },
        {
          label: 'ΚΥΑ Εσωτερικών Εγκαταστάσεων',
          date: '13/12/2023',
          link: SmartReadinessKYARe2,
        },
        {
          label: '2η Τροποποίηση ΚΥΑ_SR',
          date: '26/06/2024',
          link: SmartReadinessKYARe3,
        },
      ],
    },
    {
      category: 'Οδηγός Προγράμματος',
      documents: [
        // {
        // 	label: '2η Τροποποίηση',
        //   date: '2/12/2022',
        // 	link: SmartReadinessPdf
        // },
        // {
        // 	label: 'Τροποποίηση της Κοινής Υπουργικής Απόφασης',
        //   date: '31/12/2022',
        // 	link: SmartReadinessPdf
        // },
        // {
        // 	label: 'Οδηγός Προγράμματος',
        //   date: '22/03/2023',
        // 	link: SmartReadinessPdf
        // },
        {
          label: 'Πρόσκληση Smart Readiness (Επικαιροποιημένη)',
          date: '29/09/2023',
          link: SmartReadinessPdf2,
        },
        {
          label: 'Smart Readiness Οδηγός προγράμματος',
          date: '09/07/2024',
          link: SmartReadinessOdigos,
          
        }
      ],
    },
    {
      category: 'Διάφορα',
      documents: [
        // {
        // 	label: 'Media Pack',
        //   date: '10/06/2022',
        // 	link: MediaPack
        // },
        {
          label:
            'Οδηγός Επικοινωνίας του Εθνικού Σχεδίου Ανάκαμψης και Ανθεκτικότητας Ελλάδα 2.0',
          date: '22/03/2023',
          link: Guide,
        },
        {
          label: '1η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '07/04/2023',
          link: Guide1st,
        },
        {
          label: '2η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '28/04/2023',
          link: Guide2nd,
        },
        {
          label: '3η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '12/05/2023',
          link: Guide3rd,
        },
        {
          label: '4η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '30/05/2023',
          link: Guide4th,
        },
        {
          label: '5η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '08/06/2023',
          link: Guide5th,
        },
        {
          label: '6η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '14/06/2023',
          link: Guide6th,
        },
        {
          label: '7η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '10/07/2023',
          link: Guide7th,
        },
        {
          label: '8η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '14/07/2023',
          link: Guide8th,
        },
        {
          label: '9η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '14/09/2023',
          link: Guide9th,
        },
        {
          label: '10η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '29/09/2023',
          link: Guide10th,
        },
        {
          label: '11η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '25/10/2023',
          link: Guide11th,
        },
        {
          label: '12η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '02/11/2023',
          link: Guide12th,
        },
        {
          label: '13η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '15/11/2023',
          link: Guide13th,
        },
        {
          label: '14η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '06/12/2023',
          link: Guide14th,
        },
        {
          label: '15η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '22/12/2023',
          link: Guide15th,
        },
        {
          label: '16η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '29/01/2024',
          link: Guide16th,
        },
        {
          label: '17η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '29/02/2024',
          link: Guide17th,
        },
        {
          label: '18η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '08/03/2024',
          link: Guide18th,
        },
        {
          label: '19η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '16/04/2024',
          link: Guide19th,
        },
        {
          label: '20η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '29/05/2024',
          link: Guide20th,
        },
        {
          label: '21η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '25/06/2024',
          link: Guide21th,
        },
        {
          label: '22η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '02/08/2024',
          link: Guide22th,
        },
        {
          label: '23η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '05/09/2024',
          link: Guide23th,
        },
        {
          label: '24η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '03/10/2024',
          link: Guide24th,
        },
        {
          label: '25η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '04/11/2024',
          link: Guide25th,
        },
        {
          label: '26η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '02/12/2024',
          link: Guide26th,
        },        {
          label: '27η Απόφαση Ένταξης Μητρώου Εγκαταστατών',
          date: '07/01/2025',
          link: Guide27th,
        },
      ],
    },
    {
      category: 'Ανακοινώσεις',
      documents: [
        {
          label: 'Smart Readiness: Παρουσίαση της δράσης, πρόσφατες αλλαγές και βελτιώσεις',
          date: '09/07/2024',
          link: AnakoinwseisPpt1,
        },
        {
          label: 'Smart Readiness: Τεχνικές Προδιαγραφές Εσωτ. Δικτύου Ηλεκτρ. Επικοινωνιών',
          date: '09/07/2024',
          link: AnakoinwseisPpt2,
        },
        {
          label: 'Smart Readiness: Δικαιολογητικά Τεκμηρίωσης Κουπονιού',
          date: '05/11/2024',
          link: AnakoinwseisPpt3,
        },
        {
          label: 'Βήμα προς βήμα η διαδικασία εγγραφής στο Μητρώο Εγκαταστατών & η καταχώρηση αίτησης για την έκδοση κουπονιού SMART READINESS',
          // date: '05/11/2024',
          link: 'https://www.youtube.com/watch?v=4kpZQUiFF_s',
        },
        {
          label: 'Smart Readiness Βίντεο | Ενημερωτικός Οδηγός',
          // date: '05/11/2024',
          link: 'https://www.youtube.com/watch?v=GHe5aKh8U2k',
        }
      ],
    },
  ];

  const iframeRef = useRef(null);
  const recaptcha = useRef(null);

  // Event handler function
  window.onmessage = function (event) {
    event.preventDefault();
    //console.log('Iframe Event data has:', event.data);
    if (event.data.eligibility) {
      setElegibility(true);
      setRegion(event.data.region);
      setMunicipality(event.data.municipality);
    } else {
      setElegibility(false);
      //console.log("Building is not Eligible")
    }
    //window.grecaptcha.reset();
  };

  const onChange = event => {
    console.log('event has : ', event);
    const captchaValue = recaptcha.current.getValue(event);
    if (!captchaValue && elegibility) {
      alert('Please verify the reCAPTCHA!');
      setCaptchaPass(false);
    } else if (captchaValue && elegibility) {
      console.log('capttcha success triggered');
      Apptrackservice.getSmrData(region, municipality).then(data => {
        setResponse(data);
        setCaptchaPass(true);
      });
    }
  };

  const [generalInfo, setGeneralInfo] = useState(true);
  const [docsTab, setDocsTab] = useState(false);
  const [activeKey, setActiveKey] = useState();
  const [region, setRegion] = useState();
  const [municipality, setMunicipality] = useState();
  const [smr2public, setSmr2public] = useState(false);
  const [captChapass, setCaptchaPass] = useState(false);
  const [responceData, setResponseData] = useState(false);
  const [showBot, setShowBot] = useState(false);
  const [minimizeBot, setMinimizeBot] = useState(false);

  console.log(showBot);

  const handleMinimize = () => {
    setShowBot(false);
    setMinimizeBot(true);
  };

  const handleOpen = () => {
    setShowBot(true);
    setMinimizeBot(false);
  };

  return (
    <Layout>
      <HeaderLanding setShowBot={setShowBot} showBot={showBot} className="header-landing" />
      <Content>
        <div className="sub-header-image">
          <img src={smartSlider} alt="smartSlider" width={'100%'} />
          <div id='hero-text'>
            To Πρόγραμμα Smart Readiness υλοποιείται στο πλαίσιο του Εθνικού
            Σχεδίου Ανάκαμψης και Ανθεκτικότητας Ελλάδα 2.0 με τη χρηματοδότηση
            της Ευρωπαϊκής Ένωσης - NextGenerationEU
          </div>
        </div>
        {/* <div className='maintenance-message'>
          <p>Θα θέλαμε να σας ενημερώσουμε πως από  <b>07/07/2024</b> έως <b>08/07/2024</b> το <b>βήμα 3: Υποβολή Αίτησης</b> δεν θα είναι προσβάσιμο. Η πλατφόρμα θα είναι υπό συντήρηση. Ευχαριστούμε για την υπομονή σας </p>
        </div> */}
        <div className="sub-header-wifi">
          <Wifi />
        </div>
        <div className="sub-header mb-5">
          <h3 className="sub-header-top-text">3 απλά βήματα</h3>{' '}
          {/* <br></br> */}
          {/* <h1 className="sub-header-btm-text">AITHΣH</h1> */}
        </div>

        <div  id="iframe" className='bot' style={{ display: showBot && !minimizeBot ?"block":"none"}}>
          <div className='bot-minus'>
              <a onClick={()=>handleMinimize()}>
                <Minus style={{marginLeft:"0.625rem", marginBottom:"0.188rem"}}/>
              </a>             
          </div>
          <iframe   className="bot-iframe"
            src="https://smartreadiness.streamlit.app/?embed=true" >
          </iframe>
          <div className='bot-white'>
          </div>
        </div>

        <div  id="iframe" className='bot-minimize' style={{display: !showBot && minimizeBot? "block" : "none"}}>
          <div>
              <div>
                <a onClick={()=>handleOpen()}>
                  <img
                    src={bot_img}
                    alt="bot_img"
                    width={100} 
                    height={100} 
                  />
                </a>
              </div>            
          </div>
        </div>
        
        <div className="badges">
          <div type="link" className="badges-content">
            <a href={'/map'} target="_blank" rel="noreferrer">
              <img
                className='smart-icon'
                src={epileximotita}
                alt="epileximotita"
                // width={400} 
                // height={400} 
              />
            </a>
          </div>
          <div type="link" className="badges-content">
            <a href={'/egkatastates'} target="_blank" rel="noreferrer">
              <img
              className='smart-icon'
                src={egkatastatis}
                alt="egkatastatis"
                // width={400} 
                // height={400} 
              />
            </a>
          </div>
          <div type="link" className="badges-content">
            <a href={loginUrl2} target="_blank" rel="noreferrer">
              <img 
              className='smart-icon'
              src={aitisi} 
              alt="aitisi" 
              // width={400} 
              // height={400} 
              />
            </a>
          </div>
        </div>
        <div className="sub-badge">
          <div type="link" className="badges-content">
            <a href={loginUrl} target="_blank" rel="noreferrer">
              <img src={egkatastatis2} alt="egkatastatis2" width={'100%'} />
            </a>
          </div>
        </div>
        <div className="main-content">
          <div className="collapse-header">
            <Button
              className={generalInfo ? 'active-page' : ''}
              onClick={() => {
                setGeneralInfo(true);
                setDocsTab(false);
                setSmr2public(false);
              }}
            >
              ΓΕΝΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ
            </Button>
            <Button
              className={docsTab ? 'active-page' : ''}
              onClick={() => {
                setGeneralInfo(false);
                setDocsTab(true);
                setSmr2public(false);
              }}
            >
              ΕΓΓΡΑΦΑ ΠΡΟΓΡΑΜΜΑΤΟΣ
            </Button>
          </div>
          {docsTab && (
            <div className="documents-content">
              <h1 className="documents-header">Έγγραφα Προγράμματος</h1>
              <div className="documents-items">
                {documents.map(item => (
                  <div className="docs">
                    <h2 className="documents-category">{item.category}</h2>
                    <div className="documents-info">
                      {item.documents.map(item => (
                        <>
                          <a target="_blank" rel="noreferrer" href={item.link}>
                            {item.label}
                          </a>
                          <p>{item.date}</p>
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {generalInfo && (
            <>
              <Collapse
                key="1"
                className="main-content__collapse"
                expandIcon={({ isActive }) => (isActive ? <Minus /> : <Plus />)}
                expandIconPosition={'right'}
                onChange={e =>
                  activeKey === '1' ? setActiveKey('') : setActiveKey('1')
                }
                activeKey={activeKey}
              >
                <Panel
                  header={
                    <text style={{ color: 'white' }}>
                      Σκοπός και Αντικείμενο
                    </text>
                  }
                  key="1"
                >
                  <div>
                    <b>Σκοπός και Αντικείμενο</b>
                    <br></br>
                    <br></br>
                    <p>
                      Σκοπός της δράσης είναι η αναβάθμιση της «έξυπνης
                      ετοιμότητας» του υφιστάμενου κτιριακού αποθέματος της
                      χώρας μέσω της δημιουργίας σύγχρονων ψηφιακών υποδομών που
                      θα διευκολύνουν την μετατροπή των κτιρίων σε «έξυπνα» αλλά
                      και τη διασύνδεση τους με έξυπνους μετρητές των δικτύων
                      κοινής ωφέλειας.
                    </p>
                    <p>
                      Ειδικότερα, οι στόχοι του Προγράμματος προσδιορίζονται ως
                      ακολούθως:
                    </p>
                    <p>
                      &emsp;&emsp;● Προώθηση της διείσδυσης ευρυζωνικών
                      υπηρεσιών υπερυψηλών ταχυτήτων, τομέας στον οποίο η Ελλάδα
                      κατατάσσεται στην τελευταία θέση μεταξύ των κρατών μελών
                      της Ε.Ε.
                    </p>
                    <p>
                      &emsp;&emsp;● Προετοιμασία για την εγκατάσταση έξυπνων
                      μετρητών δικτύων κοινής ωφέλειας (ηλεκτρισμού, φυσικού
                      αερίου) χωρίς την ανάγκη σημαντικών πρόσθετων εργασιών εκ
                      μέρους των διαχειριστών δικτύων κοινής ωφέλειας.
                    </p>
                    <p>
                      &emsp;&emsp;● Προετοιμασία για την εγκατάσταση και
                      επικοινωνία κατάλληλων αισθητήρων σχετικά με την ασφάλεια,
                      τον έλεγχο καλής λειτουργίας και τη λήψη μετρήσεων που
                      αφορούν στο περιβαλλοντικό αποτύπωμα των βασικότερων
                      κοινόχρηστων εγκαταστάσεων των κτιρίων (λεβητοστάσιο
                      κεντρικής θέρμανσης και ανελκυστήρας).
                    </p>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                key="2"
                className="main-content__collapse"
                expandIcon={({ isActive }) => (isActive ? <Minus /> : <Plus />)}
                expandIconPosition={'right'}
                onChange={e =>
                  activeKey === '2' ? setActiveKey('') : setActiveKey('2')
                }
                activeKey={activeKey}
              >
                <Panel
                  header={
                    <text style={{ color: 'white' }}>
                      Δικαιούχοι του προγράμματος
                    </text>
                  }
                  key="2"
                >
                  <div>
                    <b>Δικαιούχοι του προγράμματος</b>
                    <br></br>
                    <br></br>
                    <p>
                    Στο πλαίσιο του Προγράμματος «SMART READINESS» επιχορηγούνται εργασίες που θα πραγματοποιηθούν σε κτήρια και ειδικότερα σε μονοκατοικίες και πολυκατοικίες υπό τις ακόλουθες προϋποθέσεις:
                    </p>
                    <p>
                      &emsp;&emsp;● Βρίσκονται εντός της Περιοχής Παρέμβασης και
                    </p>
                    <p>
                      &emsp;&emsp;● δε διαθέτουν ήδη ενδοκτηριακή οπτική καλωδίωση.
                    </p>
                    <p>
                    Δεν είναι επιλέξιμα και εξαιρούνται από το Πρόγραμμα τα κτήρια ιδιοκτησίας: - του Δημοσίου, των Νομικών Προσώπων Δημοσίου Δικαίου (Ν.Π.Δ.Δ.) και των Νομικών Προσώπων Ιδιωτικού Δικαίου (Ν.Π.Ι.Δ.) του ευρύτερου Δημόσιου Τομέα, - Νομικών Προσώπων που δραστηριοποιούνται στους εξαιρούμενους τομείς του Παραρτήματος III. 
                    </p>
                    <p>
                    Δικαίωμα συμμετοχής στο Πρόγραμμα έχουν τα πρόσωπα που αναφέρονται ως Ωφελούμενοι, ήτοι:
                    </p>
                    <p>
                      &emsp;&emsp;● σε περίπτωση μονοκατοικίας, το φυσικό ή νομικό πρόσωπο που τυγχάνει ιδιοκτήτης της μονοκατοικίας,
                    </p>
                    <p>
                      &emsp;&emsp;● σε περίπτωση πολυκατοικίας, η άτυπη ένωση συνιδιοκτητών κτηρίου που έχει υπαχθεί στις διατάξεις περί οροφοκτησίας (ν. 3741/1929).
                    </p>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                key="3"
                className="main-content__collapse"
                expandIcon={({ isActive }) => (isActive ? <Minus /> : <Plus />)}
                expandIconPosition={'right'}
                onChange={e =>
                  activeKey === '3' ? setActiveKey('') : setActiveKey('3')
                }
                activeKey={activeKey}
              >
                <Panel
                  header={
                    <text style={{ color: 'white' }}>
                      Επιλέξιμες Ενέργειες και δαπάνες
                    </text>
                  }
                  key="3"
                >
                  <div>
                    <b>Επιλέξιμες Ενέργειες και δαπάνες</b>
                    <br></br>
                    <br></br>
                    <p>
                    Στο πλαίσιο του Προγράμματος «SMART READINESS» επιχορηγούνται εργασίες που αφορούν στην εγκατάσταση υποδομών που θα διευκολύνουν τη μετατροπή ενός κτηρίου σε «έξυπνο».
                    </p>
                    <p>
                    Ειδικότερα, επιχορηγούνται εργασίες που περιλαμβάνουν:  
                    </p>
                    <p>
                      &emsp;&emsp;● την εγκατάσταση δικτύων οπτικών ινών εντός του κτηρίου με σκοπό τη διευκόλυνση της παροχής ευρυζωνικών υπηρεσιών υπερυψηλών ταχυτήτων σε όλους τους χώρους του κτηρίου (διαμερίσματα, γραφεία, καταστήματα κ.λπ.).
                    </p>
                    <p>
                      &emsp;&emsp;● την εγκατάσταση υποδομών για τη μελλοντική διασύνδεση των μετρητών των δικτύων κοινής ωφέλειας (ηλεκτρικής ενέργειας, φυσικού αερίου) με το κεντρικό σημείο συγκέντρωσης των υποδομών ηλεκτρονικών επικοινωνιών του κτηρίου.
                    </p>
                    <p>
                      &emsp;&emsp;● την εγκατάσταση υποδομών για τη μελλοντική διασύνδεση του λεβητοστασίου της κεντρικής θέρμανσης και του μηχανοστασίου του ανελκυστήρα με το κεντρικό σημείο συγκέντρωσης των υποδομών ηλεκτρονικών επικοινωνιών του κτηρίου.
                    </p>
                    <br />
                    <p>
                    Ειδικότερα, οι κατηγορίες επιλέξιμων εργασιών αφορούν στα ακόλουθα:
                    </p>
                    <p>
                      &emsp;&emsp;
                      <b>
                        Α. Κατασκευή της κάθετης καλωδίωσης οπτικών ινών εντός
                        κτιρίου
                      </b>{' '}
                      που περιλαμβάνει την εγκατάσταση: - Κεντρικού Κατανεμητή (Κ.Κ), - έως ενός δευτερεύοντος κατανεμητή (Floor Box-FB) ανά όροφο, - της κάθετης καλωδίωσης που συνδέει τον Κ.Κ. με τα FB. Η κάθετη καλωδίωση οπτικών ινών θα πρέπει να υποστηρίζει την παροχή ευρυζωνικών υπηρεσιών ταχύτητας τουλάχιστον 1 Gbps χωρίς την ανάγκη πρόσθετων εργασιών πλέον των εργασιών κατασκευής της οριζόντιας καλωδίωσης οπτικών ινών για τη διασύνδεση της οριζόντιας ιδιοκτησίας. - των σωληνώσεων μεταξύ του Κ.Κ. και του σημείου αναμονής του Παρόχου πρόσβασης.
                    </p>
                    <p>
                      &emsp;&emsp;
                      <b>B. Διασύνδεση των μετρητών κατανάλωσης ρεύματος</b> που
                      περιλαμβάνει την κατασκευή σωλήνωσης{' '}
                      {/* <a onClick={() => scrollToSection(redirect)}>[1]</a>  */}
                      για τη σύνδεση του χώρου φιλοξενίας μετρητών κατανάλωσης ηλεκτρικού ρεύματος με τον Κ.Κ.
                    </p>
                    <p>
                      &emsp;&emsp;
                      <b>Γ. Διασύνδεση των μετρητών φυσικού αερίου</b> που περιλαμβάνει την κατασκευή σωλήνωσης για τη σύνδεση του χώρου φιλοξενίας μετρητών φυσικού αερίου με τον Κ.Κ.
                    </p>
                    <p>
                      &emsp;&emsp;
                      <b>
                        Δ. Διασύνδεση του λεβητοστασίου της κεντρικής θέρμανσης
                      </b>{' '}
                      που περιλαμβάνει την κατασκευή σωλήνωσης για τη σύνδεση του χώρου στέγασης του λέβητα κεντρικής θέρμανσης με τον Κ.Κ.
                    </p>
                    <p>
                      &emsp;&emsp;
                      <b>Ε. Διασύνδεση του μηχανοστασίου του ανελκυστήρα</b> που περιλαμβάνει την κατασκευή σωλήνωσης για τη σύνδεση του μηχανοστασίου του ανελκυστήρα με τον Κ.Κ.
                    </p>
                    <br />
                    {/* <p>
                      <b>
                        Προκειμένου ένα κτήριο να λάβει χρηματοδότησης στο
                        πλαίσιο του Προγράμματος θα πρέπει να πραγματοποιηθούν
                        υποχρεωτικά εργασίες που αντιστοιχούν στο στοιχείο Α
                        και, επιπλέον, τουλάχιστον μία από τις εργασίες που
                        αντιστοιχούν σε οποιοδήποτε εκ των στοιχείων Β έως Ε.
                      </b>
                    </p>
                    <br />
                    <p>
                      Για να δείτε τα χαρακτηριστικά των κατηγοριών επιλέξιμων
                      εργασιών παρακαλούμε συμβουλευτείτε την{' '}
                      <a
                        href={SmartReadinessPdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        ενότητα 2.3 του Οδηγού προγράμματος.
                      </a>
                    </p>
                    <p>
                      Εξαιρούνται της χρηματοδότησης τα μέρη του δικτύου που
                      αφορούν σε:
                    </p>
                    <p>
                      &emsp;&emsp;● Εργασίες σε δημόσιο χώρο (εκτός του
                      οικοπέδου του κτιρίου), εκτός αν πρόκειται για τη σύνδεση
                      των μετρητών φυσικού αερίου του ωφελούμενου κτιρίου που
                      βρίσκονται τοποθετημένοι στην πρόσοψη του κτιρίου ή της
                      εξωτερικής μάντρας του οικοπέδου καθώς και εργασίες
                      προκειμένου το σημείο απόληξης της σωλήνωσης εισαγωγής
                      προς το BEP να ταυτίζεται με το σημείο αναμονής του
                      παρόχου πρόσβασης (τυπικά στο ρείθρο του πεζοδρομίου
                      έμπροσθεν του κτιρίου).
                    </p>
                    <p>
                      &emsp;&emsp;● Σύνδεση του FB με την οπτική πρίζα (OTO -
                      Optical Terminal Outlet) εντός οριζόντιων ιδιοκτησιών.
                    </p>
                    <p>
                      &emsp;&emsp;● Εργασίες εκτός των κοινόχρηστων χώρων των
                      κτιρίων.
                    </p>
                    <br />
                    <hr />
                    <p ref={redirect}>
                      <a onClick={() => scrollToSection(redirect)}>[1]</a> Η
                      τοποθέτηση του καλωδίου εντός της σωλήνωσης δύναται να
                      πραγματοποιηθεί μεταγενέστερα από τον ΔΕΔΔΗΕ (Β) / τον
                      πάροχο φυσικού αερίου (Γ) / τον αρμόδιο
                      συνεργάτη-συντηρητή του κτιρίου (Δ και Ε) σύμφωνα με τις
                      προδιαγραφές του σχετικού εξοπλισμούς που θα εγκατασταθεί
                    </p> */}
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                key="4"
                className="main-content__collapse"
                expandIcon={({ isActive }) => (isActive ? <Minus /> : <Plus />)}
                expandIconPosition={'right'}
                onChange={e =>
                  activeKey === '4' ? setActiveKey('') : setActiveKey('4')
                }
                activeKey={activeKey}
              >
                <Panel
                  header={
                    <text style={{ color: 'white' }}>Ποσό Ενίσχυσης</text>
                  }
                  key="4"
                >
                  <div>
                    <b>Ποσό Ενίσχυσης</b>
                    <br></br>
                    <br></br>
                    <p>
                      Στο πλαίσιο της δράσης προβλέπεται η επιχορήγηση μέρους
                      του κόστους των εργασιών που αντιστοιχούν στις ανωτέρω
                      αναφερόμενες εργασίες. Το συνολικό ποσό της ενίσχυσης ανά
                      κτήριο προκύπτει με βάση τον αριθμό των ορόφων, των
                      οριζόντιων ιδιοκτησιών και την κατηγορία των εργασιών που
                      θα πραγματοποιηθούν. Πιο συγκεκριμένα, το ποσό ενίσχυσης
                      καθορίζεται ως ακολούθως:
                    </p>
                    <br />
                    <Table
                      className="main-content__table"
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                    />
                    <br />
                    <p>
                      Η επιχορήγηση δίνεται μόνο μία φορά σε κάθε κτήριο. Οι
                      δαπάνες που ενισχύονται στο πλαίσιο του Προγράμματος δεν
                      επιτρέπεται να ενισχυθούν από καμία άλλη πηγή, Εθνική ή
                      Ευρωπαϊκή.
                    </p>
                    <p>
                      Για περισσότερες πληροφορίες σχετικά με τον προϋπολογισμό
                      και τις λοιπές σχετικές λεπτομέρειες της ενίσχυσης,
                      παρακαλούμε συμβουλευτείτε την{' '}
                      <a
                        href={SmartReadinessPdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        την ενότητα 6 του Οδηγού του προγράμματος.
                      </a>
                    </p>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                key="5"
                className="main-content__collapse"
                expandIcon={({ isActive }) => (isActive ? <Minus /> : <Plus />)}
                expandIconPosition={'right'}
                onChange={e =>
                  activeKey === '5' ? setActiveKey('') : setActiveKey('5')
                }
                activeKey={activeKey}
              >
                <Panel
                  header={
                    <text style={{ color: 'white' }}>
                      Διαδικασία Ένταξης δικαιούχων στο Πρόγραμμα
                    </text>
                  }
                  key="5"
                >
                  <div>
                    <b>Διαδικασία Ένταξης δικαιούχων στο Πρόγραμμα</b>
                    <br></br>
                    <br></br>
                    <p>
                      Προκειμένου ένα κτήριο να ενταχθεί στο Πρόγραμμα, ο
                      Διαχειριστής του κτιρίου θα πρέπει να ακολουθηθούν τα
                      παρακάτω βήματα:
                    </p>
                    <p>
                      &emsp;&emsp;Ο Διαχειριστής του κτιρίου ελέγχει{' '}
                      <a
                        href={SmartReadinessPdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        στη σχετική ιστοσελίδα του Προγράμματος
                      </a>{' '}
                      εάν το κτήριο βρίσκεται εντός της περιοχής παρέμβασης.
                    </p>
                    <p>
                      &emsp;&emsp;Εφόσον το κτήριο βρίσκεται εντός των περιοχών
                      παρέμβασης και είναι επιλέξιμο, ο Διαχειριστής μπορεί να
                      δει τους Εγκαταστάτες οι οποίοι είναι εγγεγραμμένοι στο
                      Μητρώο Εγκαταστατών του Προγράμματος και
                      δραστηριοποιούνται στην περιοχή του καθώς και τα στοιχεία
                      επικοινωνίας τους.
                    </p>
                    <p>
                      &emsp;&emsp;Ο Διαχειριστής προβαίνει σε επικοινωνία με τον
                      Εγκαταστάτη της επιλογής του, ο οποίος επισκέπτεται το
                      κτήριο, προσδιορίζει επακριβώς τις εργασίες που πρόκειται
                      να εκτελεστούν και υποβάλλει την προσφορά του προς τον
                      Διαχειριστή.
                    </p>
                    <p>
                      Μετά την αποδοχή εκ μέρους του Διαχειριστή της προσφοράς,
                      ο Εγκαταστάτης αναλαμβάνει την υποβολή αίτησης έκδοσης
                      Voucher όπως αναλυτικά περιγράφεται{' '}
                      <a
                        href={SmartReadinessPdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        στην ενότητα 12 του Οδηγού προγράμματος.
                      </a>
                    </p>
                  </div>
                </Panel>
              </Collapse>
            </>
          )}
        </div>
      </Content>
      <div className="landing-page-footer-smart">
        <Footer />
      </div>
    </Layout>
  );
};

export default LandingPageSmart;
